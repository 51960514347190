<ng-container>
  <a
    [routerLink]="path"
    class="cat-container"
  >
    <span class="cat-bg-white"></span>
    <span class="cat-bg-radiant"></span>
    <span class="cat-bg-rounded-white"></span>

    <span class="cat-label-container">
      @if (picture) {
        <span
          class="cat-picture"
          [style.background-image]="getBgImage()"
        ></span>
      }

      <span class="cat-label">
        <ng-content/>

        @if (description) {
          <span class="cat-desc">
            <wysiwyg-content
              [content]="description"
            />
          </span>
        }
      </span>
    </span>
  </a>
</ng-container>
