import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import {
  TranslationColumnValueModel,
  TranslationModule,
  WysiwygModule,
} from '@ckmk/angular';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'cat-btn',
  imports: [RouterLink, TranslationModule, WysiwygModule],
  templateUrl: './cat-btn.component.html',
  styleUrl: './cat-btn.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CatBtnComponent implements OnInit, OnChanges {
  @Input({
    required: false,
  })
  public colorLeft?: string;

  @Input({
    required: false,
  })
  public colorRight?: string;

  @Input({
    required: false,
  })
  public picture?: string;

  @Input({
    required: false,
  })
  public description?: TranslationColumnValueModel | string;

  @Input({
    required: false,
  })
  public path: string | (string | undefined)[] = '#';

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this.toggleRadiantColors();
  }

  public ngOnChanges({ colorLeft, colorRight }: SimpleChanges) {
    if (colorLeft && !colorLeft.firstChange) {
      this.toggleRadiantColors();
    }

    if (colorRight && !colorRight.firstChange) {
      this.toggleRadiantColors();
    }
  }

  protected getColorLeft(): string {
    if (this.colorLeft) {
      return this.colorLeft;
    }

    return '#72b3cf';
  }

  protected getColorRight(): string {
    if (this.colorRight) {
      return this.colorRight;
    }

    return '#4f839a';
  }

  protected getBgImage(): string {
    if (this.picture) {
      return `url("${this.picture}")`;
    }

    return '';
  }

  protected toggleRadiantColors(): void {
    const element: HTMLElement = this._self.nativeElement as HTMLElement;

    element.style.setProperty('--left-color', this.getColorLeft());
    element.style.setProperty('--right-color', this.getColorRight());
  }
}
